<template>
  <div class="home">
    客户端

    <el-space wrap class="classifys">
      <div v-for="(item, index) in classifys" :key="item.id" @click="click_classify(item)" :class="{ classifyActive: item == classify, classify: true, classifySpace: index % 2 == 0 }">
        <div class="classify_content">
          {{ item.name }}
          <div class="includeCount">包含6种商品</div>
        </div>
      </div>
    </el-space>

    <el-space wrap class="goods">
      <el-card v-for="item in goods" :key="item.id" @click="click_good(item)" :class="{ goodActive: item == good }" direction="vertical">
        {{ item.name }}
        <div class="price">￥{{ item.price }}</div>
      </el-card>
    </el-space>

    <el-row :gutter="20" class="Submit">
      <el-col :span="8" class="fastOpe">
        <el-button type="primary" icon="Edit" circle />
        <el-button type="success" icon="Check" circle />
      </el-col>
      <el-col :span="16" class="PaymentMethod"> 使用支付宝支付 ￥{{ good.price * count }} </el-col>
    </el-row>

    <el-card class="describe">
      <template #header>
        <div class="card-header">商品简介</div>
      </template>
      <span>{{ good.describe }}</span>
    </el-card>

    <el-input-number v-model="count" :min="good.min" :max="good.max" @change="handleCount" />
  </div>
</template>

<script setup >
import { ref, onMounted, reactive } from 'vue'
import Api from '@/network'

let count = ref(1)
let good = ref({})
let classify = ref({})
let GoodApi = Api.get_baseApi('good')
let goods = ref([])
let ClassifyApi = Api.get_baseApi('classify')
let classifys = ref([])

onMounted(async () => {
  let { data } = await ClassifyApi.$get_page()
  classifys.value = data.rows
  classify.value = data.rows[0]

  let { data: goodDate } = await GoodApi.$get_page()
  goods.value = goodDate.rows
  good.value = goodDate.rows[0]
})

const click_classify = (item) => {
  classify.value = item
}
const click_good = (item) => {
  good.value = item
}
const handleCount = (value) => {

}



</script>

<style lang="less" >
.home {
  .classifys {
    .el-space__item {
      margin-right: 0px !important;
    }
  }

  padding: 2vw;
  .goods {
    margin-top: 40px;
  }
  .classifyActive {
    background-color: var(--el-color-primary);
    .classify_content {
      color: white;
      .includeCount {
        color: white;
      }
    }
  }
  .goodActive {
    border: 1px solid var(--el-color-primary);
    overflow: hidden;

    .el-card__body {
      color: var(--el-color-primary);
    }
  }
}
.classifySpace {
  margin-right: 2vw;
}
.classify {
  background-color: #f7f7f7;
  border-radius: 4px;
  width: 47vw;
  .classify_content {
    margin: 20px;
    color: #545454;
    font-size: 13px;
    font-weight: 700;
    .includeCount {
      margin-top: 10px;
      font-size: 12px;
      color: #8a8a8a;
    }
  }
}
.goods {
  font-size: 13px;
  font-weight: 700;
  .el-card__body {
    color: #545454;
  }
  .price {
    margin-top: 8px;
    color: var(--el-color-primary);
  }
}

.Submit {
  position: fixed;
  bottom: 0;
  width: 100%;
  font-weight: 700;
  height: 60px;
  text-align: center;
  line-height: 60px;
  box-shadow: 0 0 0.2rem #efefef;
  .PaymentMethod {
    background-color: var(--el-color-primary);
    color: white;
    font-size: 17px;
  }
}
.describe {
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    font-size: 12px;
    color: #999;
    font-weight: 700;
  }
}
</style>
